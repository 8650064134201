import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTheme } from '@/contexts/ThemeContext';

export default function InfoModal() {
  const theme = useTheme();
  const IS_BANK = process.env.NEXT_PUBLIC_IS_BANK === 'true';
  const HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME;
  const [open, setOpen] = useState(true);
  const [contactMail, setContactMail] = useState('');
  const [MZAdress, setMZAdress] = useState('');
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (theme.slug !== null && theme.slug?.includes('dl.')) {
      const domain = theme.slug?.replace('dl', 'member');
      setMZAdress(`https://${domain}`);
    }
  }, []);

  useEffect(() => {
    if (IS_BANK) {
      const domain = window.location.origin.replace('apply.', '');

      setContactMail(domain);
    }
    if (theme?.slug?.includes('dl.')) {
      const domain = theme.slug.replace('dl.', '');

      setContactMail(domain);
    }
  }, [theme.slug]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="py-5 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h2"
                        className="text-base text-center font-semibold leading-6 text-gray-900 pb-5"
                      >
                        🛑 Important Announcement 🛑
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          📌 To Our Future Clients: <br />
                          <br />
                          We&apos;re taking a moment to recalibrate and are
                          pausing new subscriptions. We truly appreciate your
                          interest in our business. Please stay tuned for
                          updates. We hope to serve you soon. <br />
                          <br />
                          📌 To Our Valued Members: <br />
                          <br />
                          Your experience remains our top priority. For any
                          queries or support, kindly contact our customer
                          service: <br />
                          <br />
                          ✉️ Email:{' '}
                          <a
                            href={
                              contactMail
                                ? `mailto:contact@${contactMail}`
                                : `mailto:contact@${HOSTNAME}`
                            }
                            className="leading-6 text-gray-900 hover:text-gray-900 hover:underline"
                          >
                            {contactMail
                              ? `contact@${contactMail}`
                              : `contact@${HOSTNAME}`}
                          </a>{' '}
                          <br />
                          <br />
                          If you wish to manage your account, please access your{' '}
                          <a
                            href={
                              MZAdress
                                ? MZAdress
                                : process.env.NEXT_PUBLIC_MZ_ADDRESS
                            }
                            className="leading-6 text-gray-900 hover:text-gray-900 hover:underline"
                          >
                            member zone
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
