export default function Disclaimer(): JSX.Element {
  return (
    <div className="bg-gray-600 text-center">
      <div className="max-w-screen-xl mx-auto py-1 text-center">
        <span className="text-white text-xs italic px-6 md:px-3">
          We are in no way affiliated with the UK Government. We are a third
          party agency which charges a service fee of £89 every 3 months with a
          3-day free trial. You do not need to use our service to apply for a UK
          driving licence. You may instead apply for one directly via the UK
          Government website at{' '}
          <a
            className="underline"
            href="https://www.gov.uk"
            target="_blank"
            rel="noreferrer"
          >
            www.gov.uk
          </a>
          , for which they may or may not charge a fee.
        </span>
      </div>
    </div>
  );
}
