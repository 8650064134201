declare global {
  interface Window {
    dataLayer: any;
  }
}

export const GTMPageView = (url: string): any => {
  const pageEvent = {
    event: 'pageview',
    page: url,
  };

  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};
