import { createContext, useContext, Context } from 'react';

type Color = {
  hue: number;
  saturation: number;
  brightness: number;
  alpha?: number;
};

export type Theme = {
  slug: string | null;
  headerColor: Color;
  primaryColor: Color;
  primaryDarkColor: Color;
  boxHeaderColor: Color;
  boxBackgroundColor: Color;
  boxBorderColor: Color;
  progressBar: 'v1' | 'v2' | 'v3';
  landingPage:
    | 'v0'
    | 'v1'
    | 'v2'
    | 'v3'
    | 'v4'
    | 'v5'
    | 'v6'
    | 'v7'
    | 'v8'
    | 'v9'
    | 'v10'
    | 'v11'
    | 'v12'
    | 'v13';
  scriptEnabled: boolean;
  script: string | null;
  confirmationScriptEnabled: boolean;
  confirmationScript: string | null;
  abTestingScriptEnabled: boolean;
  abTestingScript: string | null;
  cloackingEnabled: boolean;
  editingEnabled: boolean;
  servicesWording: string;
  gAnalyticsEnabled: boolean;
  gAnalyticsId: string | null;
  gtmEnabled: boolean;
  gtmID: string | null;
};

export const initialState: Theme = {
  slug: null,
  headerColor: {
    hue: 217,
    saturation: 0.26,
    brightness: 0.2,
  },
  primaryColor: {
    hue: 120,
    saturation: 0.23,
    brightness: 0.3,
  },
  primaryDarkColor: {
    hue: 113,
    saturation: 1,
    brightness: 0.15,
  },
  boxHeaderColor: {
    hue: 217,
    saturation: 0.26,
    brightness: 0.2,
  },
  boxBackgroundColor: {
    hue: 220,
    saturation: 0.13,
    brightness: 0.91,
  },
  boxBorderColor: {
    hue: 216,
    saturation: 0.13,
    brightness: 0.84,
  },
  progressBar: 'v1',
  landingPage: 'v0',
  scriptEnabled: false,
  script: null,
  confirmationScriptEnabled: false,
  confirmationScript: null,
  abTestingScriptEnabled: false,
  abTestingScript: null,
  editingEnabled: false,
  servicesWording: '',
  cloackingEnabled: false,
  gAnalyticsEnabled: false,
  gAnalyticsId: null,
  gtmEnabled: false,
  gtmID: null,
};

export const boTheme: Theme = {
  slug: null,
  headerColor: {
    hue: 217,
    saturation: 0.26,
    brightness: 0.9,
  },
  primaryColor: {
    hue: 134,
    saturation: 1,
    brightness: 0.22,
  },
  primaryDarkColor: {
    hue: 134,
    saturation: 1,
    brightness: 0.13,
  },
  boxHeaderColor: {
    hue: 44,
    saturation: 0,
    brightness: 0.4,
  },
  boxBackgroundColor: {
    hue: 220,
    saturation: 0.13,
    brightness: 0.95,
  },
  boxBorderColor: {
    hue: 216,
    saturation: 0.13,
    brightness: 0.9,
  },
  progressBar: 'v3',
  landingPage: 'v1',
  scriptEnabled: false,
  script: null,
  confirmationScriptEnabled: false,
  confirmationScript: null,
  abTestingScriptEnabled: false,
  abTestingScript: null,
  editingEnabled: false,
  servicesWording: '',
  cloackingEnabled: false,
  gAnalyticsEnabled: false,
  gAnalyticsId: null,
  gtmEnabled: false,
  gtmID: null,
};

export const ThemeContext: Context<Theme> = createContext(initialState);

export const useTheme = (): Theme => useContext(ThemeContext);
