import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { createStore, StateMachineProvider } from 'little-state-machine';
import * as Fathom from 'fathom-client';

import { GTMPageView } from '@/utils/GTMPageView';
import { ThemeContext, initialState, Theme } from '@/contexts/ThemeContext';
import GdprBanner from '@/components/layout/GdprBanner';
import Disclaimer from '@/components/layout/Disclaimer';

import '../styles/index.css';
import { checkIfSubscriptionTheme } from '@/utils/helpers';
import InfoModal from '@/components/InfoModal';
import PiwikProProvider from '@piwikpro/next-piwik-pro';

createStore({ form: { licenceNumber: '', nin: '', postcode: '' } }, null);

const Header = dynamic(() => import('@/components/layout/Header'));
const Footer = dynamic(() => import('@/components/layout/Footer'));
const V12Footer = dynamic(() => import('@/components/V12/Footer'));
const V4Header = dynamic(() => import('@/components/layout/v4/Header'));
const V5Header = dynamic(() => import('@/components/layout/v5/Header'));
const V4Footer = dynamic(() => import('@/components/layout/v4/Footer'));
const V0Header = dynamic(() => import('@/components/layout/login/Header'));

const ENV = process.env.NEXT_PUBLIC_ENV;
const isProd = ENV === 'production';

function MyApp({
  Component,
  pageProps,
}: AppProps<{ theme?: Theme }>): JSX.Element {
  const [customerId, setCustomerId] = useState<string>(null);
  const [notMobile, setNotMobile] = useState(true);
  const router = useRouter();
  const theme: Theme = pageProps.theme || initialState;
  const slug = pageProps.theme?.slug;
  const isOlyaTheme = ['oa', 'oa2', 'oa3'].includes(slug);
  const isVadymTheme = slug === 'vadym';
  const isDlAssistant = ['v12', 'v13'].includes(theme.landingPage);
  const isSubscriptionTheme = checkIfSubscriptionTheme(slug, theme.landingPage);
  const isCloackingEnabled = theme.cloackingEnabled;
  const AW_ID = theme.scriptEnabled
    ? theme.script
    : process.env.NEXT_PUBLIC_AW_ID;
  const GA_ID = theme.gAnalyticsEnabled
    ? theme.gAnalyticsId
    : process.env.NEXT_PUBLIC_GA_ID;
  const GTM_ID = theme.gtmEnabled
    ? theme.gtmID
    : process.env.NEXT_PUBLIC_GTM_ID;
  const isV4 = theme.landingPage === 'v4';
  const isV5 = theme.landingPage === 'v5';
  const isV0 = theme.landingPage === 'v0';
  const isV12 = theme.landingPage === 'v12';
  const isV13 = theme.landingPage === 'v13';
  const headerColor = `hsl(
    ${theme.headerColor.hue}deg 
    ${theme.headerColor.saturation * 100}% 
    ${theme.headerColor.brightness * 100}% / ${theme.headerColor?.alpha || '1'}
  )`;
  const primaryColor = `hsl(
    ${theme.primaryColor.hue}deg 
    ${theme.primaryColor.saturation * 100}% 
    ${theme.primaryColor.brightness * 100}% / ${
    theme.primaryColor?.alpha || '1'
  }
  )`;
  const primaryDarkColor = `hsl(
    ${theme.primaryDarkColor.hue}deg 
    ${theme.primaryDarkColor.saturation * 100}% 
    ${theme.primaryDarkColor.brightness * 100}% / ${
    theme.primaryDarkColor?.alpha || '1'
  }
  )`;
  const boxHeaderColor = `hsl(
    ${theme.boxHeaderColor.hue}deg 
    ${theme.boxHeaderColor.saturation * 100}% 
    ${theme.boxHeaderColor.brightness * 100}% / ${
    theme.boxHeaderColor?.alpha || '1'
  }
  )`;
  const boxBackgroundColor = `hsl(
    ${theme.boxBackgroundColor.hue}deg 
    ${theme.boxBackgroundColor.saturation * 100}% 
    ${theme.boxBackgroundColor.brightness * 100}% / ${
    theme.boxBackgroundColor?.alpha || '1'
  }
  )`;
  const boxBorderColor = `hsl(
    ${theme.boxBorderColor.hue}deg 
    ${theme.boxBorderColor.saturation * 100}% 
    ${theme.boxBorderColor.brightness * 100}% / ${
    theme.boxBorderColor?.alpha || '1'
  }
  )`;
  const isHome = router.pathname === '/';
  const isLS =
    typeof window !== 'undefined' &&
    window.location.href.indexOf('driving-licence-support-online.co') !== -1;
  const isAO =
    typeof window !== 'undefined' &&
    window.location.href.indexOf('driving-service-support-assistance.com') !==
      -1;
  const piwikContainerId = isOlyaTheme
    ? 'd8077509-4fdc-49f9-a793-878a7838668d'
    : '2af0e985-ec0b-4571-87f8-63c419363c17';

  useEffect(() => {
    if (isOlyaTheme) {
      Fathom.load('KORUGEMB');
    }

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const requestedVariant = params.get('v') as variant;
      const customerId =
        params.get('customerId') || localStorage.getItem('customerId');
      const theme = params.get('theme');
      const gclid = params.get('gclid');
      const keyword = params.get('keyword');
      const utmSource = params.get('utm_source');
      const utmMedium = params.get('utm_medium');
      const utmCampaign = params.get('utm_campaign');

      setNotMobile(window.innerWidth > 768);

      if (['1', '2', '3', '4'].includes(requestedVariant)) {
        window.localStorage.setItem('v', requestedVariant);
      }

      if (customerId) {
        localStorage.setItem('customerId', customerId);
        setCustomerId(customerId);
      }

      if (theme) window.localStorage.setItem('theme', theme);
      if (gclid) window.localStorage.setItem('gclid', gclid);
      if (keyword) window.localStorage.setItem('keyword', keyword);
      if (utmSource) window.localStorage.setItem('utm_source', utmSource);
      if (utmMedium) window.localStorage.setItem('utm_medium', utmMedium);
      if (utmCampaign) window.localStorage.setItem('utm_campaign', utmCampaign);
    }
    if (ENV === 'production') {
      const handleRouteChange = (url: string) => {
        GTMPageView(url);
        Fathom.trackPageview();
      };
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, []);

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href={
            isLS
              ? '/favicon-ls.ico'
              : isAO
              ? '/favicon-ao.ico'
              : isDlAssistant
              ? '/favicon-da.ico'
              : '/favicon.ico'
          }
        />

        <style
          dangerouslySetInnerHTML={{
            __html: `
            :root {
              --color-header: ${headerColor};
              --color-primary: ${primaryColor};
              --color-primary-dark: ${primaryDarkColor};
              --color-box-header: ${boxHeaderColor};
              --color-box-background: ${boxBackgroundColor};
              --color-box-border: ${boxBorderColor};
            }
            `,
          }}
        />

        {/* Google Tag Manager */}
        {GTM_ID && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');`,
            }}
          />
        )}
        {/* End Google Tag Manager */}

        {/* Google Analytics */}
        {GA_ID && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                
                  gtag('config', '${GA_ID}');`,
              }}
            />
          </>
        )}
        {/* End Google Analytics */}

        {/* Google Adwords Tag */}
        {AW_ID && !isCloackingEnabled ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${AW_ID}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                
                  gtag('config', '${AW_ID}');`,
              }}
            />
          </>
        ) : null}
        {/* End Google Adwords Tag */}

        {/* Bing Tag */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"134612046"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
          }}
        />
        {/* End Bing Tag */}

        {/* Verizon Script */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10160057','userEmail': '<email_address>'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`,
          }}
        />
        {/* End Verizon Script */}

        {/* Matomo Script for Vadym theme  */}
        {isVadymTheme && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `var _paq = window._paq = window._paq || [];
                  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                  _paq.push(['trackPageView']);
                  _paq.push(['enableLinkTracking']);
                  (function() {
                    var u="https://drivinghelpcoukthemevadym.matomo.cloud/";
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', '1']);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='//cdn.matomo.cloud/drivinghelpcoukthemevadym.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
                  })();`,
              }}
            />
          </>
        )}
        {/* End Matomo Script for Vadym theme */}

        {/* A/B Testing Script */}
        {theme.abTestingScriptEnabled && (
          <script
            dangerouslySetInnerHTML={{
              __html: theme.abTestingScript,
            }}
          />
        )}
        {/* End A/B Testing Script */}
      </Head>
      <StateMachineProvider>
        <ThemeContext.Provider value={theme}>
          {!isProd && (
            <div className="relative z-10 bg-red-500 py-2 text-center text-white text-2xl font-semibold uppercase">
              Staging Environment
            </div>
          )}
          <PiwikProProvider
            containerUrl="https://sanjow.containers.piwik.pro"
            containerId={piwikContainerId}
          >
            <div className={isV13 || isV12 ? 'font-raleway' : 'font-sans'}>
              {!customerId ? (
                <>
                  {!isV12 && !isV13 && (
                    <>
                      {isHome &&
                        isSubscriptionTheme &&
                        notMobile &&
                        slug !== null &&
                        !slug?.startsWith('dl.') && <Disclaimer />}

                      {(slug === null || slug?.startsWith('dl.')) && (
                        <Disclaimer />
                      )}
                    </>
                  )}
                  {isV4 ? (
                    <V4Header />
                  ) : isV5 ? (
                    <V5Header />
                  ) : isV0 ? (
                    <V0Header />
                  ) : isV12 || isV13 ? (
                    <></>
                  ) : (
                    <Header />
                  )}
                  <Component {...pageProps} />
                  {isV4 ? (
                    <V4Footer />
                  ) : isV12 || isV13 ? (
                    <V12Footer />
                  ) : (
                    <Footer />
                  )}
                  <GdprBanner />
                </>
              ) : (
                <Component {...pageProps} />
              )}
              {isV12 || isV13 ? <></> : <InfoModal />}
            </div>
          </PiwikProProvider>
        </ThemeContext.Provider>
      </StateMachineProvider>
    </>
  );
}

export default MyApp;
