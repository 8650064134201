import { useEffect, useState } from 'react';

import Link from 'next/link';

export default function GdprBanner(): JSX.Element {
  const [hideGdpr, setHideGdpr] = useState(false);

  const hideBanner = () => {
    localStorage.setItem('hideGdpr', 'yes');
    setHideGdpr(true);
  };

  useEffect(() => {
    setHideGdpr(!!localStorage.getItem('hideGdpr'));
  }, []);

  return (
    <>
      {hideGdpr ? null : (
        <div className="hidden fixed inset-x-0 bottom-0 text-white bg-gray-600 xl:block">
          <div className="max-w-screen-xl mx-auto flex justify-between py-2 text-sm">
            <span>
              By using this website, you accept our{' '}
              <Link href="/privacy-policy">
                <a className="underline">privacy and cookie policies</a>
              </Link>
              .
            </span>
            <button onClick={hideBanner}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
