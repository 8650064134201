import { Theme } from '@/contexts/ThemeContext';
import Cookies from 'cookies';
import { GetServerSidePropsResult } from 'next';
import { NextRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import { boTheme, initialState } from '@/contexts/ThemeContext';
import { GlobalState } from 'little-state-machine';
import dayjs from 'dayjs';

const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;
const ENV = process.env.NEXT_PUBLIC_ENV;
const WEBSITE_UUID = process.env.NEXT_PUBLIC_WEBSITE_UUID;

const SUBSCRIPTION_1M = process.env.NEXT_PUBLIC_SUBSCRIPTION_1M;
const SUBSCRIPTION_2M = process.env.NEXT_PUBLIC_SUBSCRIPTION_2M;
const SUBSCRIPTION_3M = process.env.NEXT_PUBLIC_SUBSCRIPTION_3M;
const SUBSCRIPTION_3M_7D = process.env.NEXT_PUBLIC_SUBSCRIPTION_3M_7D;
const SUBSCRIPTION_14D = process.env.NEXT_PUBLIC_SUBSCRIPTION_14D;
const SUBSCRIPTION_NO_TRIAL = process.env.NEXT_PUBLIC_SUBSCRIPTION_NO_TRIAL;

type SaveSubscriptionType = {
  products: string[];
  application: Record<string, any>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  router: NextRouter;
  isCloackingEnabled: boolean;
  customerId?: string;
  isFullySubmitted?: boolean;
};

export const formatPostCode = (postCode: string): string => {
  postCode = postCode.replace(' ', '');
  if (postCode.length == 5) {
    postCode = postCode.substring(0, 2) + ' ' + postCode.substring(2, 5);
  } else if (postCode.length == 6) {
    postCode = postCode.substring(0, 3) + ' ' + postCode.substring(3, 6);
  } else if (postCode.length == 7) {
    postCode = postCode.substring(0, 4) + ' ' + postCode.substring(4, 7);
  }

  return postCode.replace(/\s+/g, ' ').trim().toUpperCase();
};

export const formatCurrency = (currency: 'usd' | 'gbp'): '$' | '£' =>
  currency.toLowerCase() === 'usd' ? '$' : '£';

export const saveApplication = async (
  products: string[],
  application: unknown,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  router: NextRouter,
  customerId: string | null,
  isCloackingEnabled: boolean
): Promise<void> => {
  setIsLoading(true);
  localStorage.setItem('application', JSON.stringify(application));

  try {
    const gclid = localStorage.getItem('gclid');
    const themeSlug = localStorage.getItem('theme');
    const keyword = localStorage.getItem('keyword');
    const data = {
      websiteId: WEBSITE_UUID,
      type: 'dl',
      data: application,
      products: [...products],
      customerId,
      themeSlug,
      ...(gclid && themeSlug && isCloackingEnabled
        ? {
            conversion: {
              gclid,
              themeSlug,
              keyword,
            },
          }
        : null),
    };

    const res = await fetch(`${API_ENDPOINT}/applications`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((res) => res.json());

    if (res.success) {
      const { isFree, applicationId } = res;
      localStorage.setItem('appId', applicationId);

      if (isFree) {
        if (customerId) {
          let boUrl = '';
          if (ENV !== 'production') {
            boUrl =
              'https://staging.sanjow.com/members/applications?thankyou=true';
          } else {
            boUrl =
              'https://admin.sanjow.com/members/applications?thankyou=true';
          }

          window.location.assign(boUrl);
        } else {
          router.push(`/thankyou`);
          window.scrollTo(0, 150);
        }
      } else {
        router.push(`/application?id=${applicationId}`);
        window.scrollTo(0, 150);
      }
    } else {
      setIsLoading(false);
      alert(res.message);
    }
  } catch (e) {
    setIsLoading(false);
    alert('Unexpected error happened, please refresh the page and try again');
  }
};

export const saveSubscription = async ({
  products,
  application,
  setIsLoading,
  router,
  customerId,
  isCloackingEnabled,
  isFullySubmitted = true,
}: SaveSubscriptionType): Promise<boolean> => {
  setIsLoading(true);
  localStorage.setItem('application', JSON.stringify(application));

  try {
    const gclid = localStorage.getItem('gclid');
    const themeSlug = localStorage.getItem('theme');
    const keyword = localStorage.getItem('keyword');
    const result = await fetch(`${API_ENDPOINT}/subscriptions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        websiteId: WEBSITE_UUID,
        data: application,
        type: 'dl',
        products: [...products],
        customerId,
        isFullySubmitted,
        themeSlug,
        ...(gclid && themeSlug && isCloackingEnabled
          ? {
              conversion: {
                gclid,
                themeSlug,
                keyword,
              },
            }
          : null),
      }),
    }).then((res) => res.json());

    if (result.success) {
      const { applicationId } = result;

      if (applicationId) {
        localStorage.setItem('appId', applicationId);

        router.push({
          pathname: '/application',
          query: { id: applicationId },
        });
      }

      window.scrollTo(0, 150);
    } else {
      setIsLoading(false);
      alert(result.message);
    }

    return result.success;
  } catch (e) {
    setIsLoading(false);
    alert('Unexpected error happened, please refresh the page and try again');
  }
};

export const editApplication = async ({
  application,
  applicationId,
  products,
  router,
}: {
  application: Record<string, any>;
  applicationId: string;
  products: string[];
  router: NextRouter;
}): Promise<void> => {
  localStorage.setItem('application', JSON.stringify(application));

  const result = await fetch(`${API_ENDPOINT}/applications/${applicationId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      websiteId: WEBSITE_UUID,
      data: application,
      products,
      isFullySubmitted: true,
    }),
  }).then((res) => res.json());

  if (result.success) {
    router.push({
      pathname: '/application',
      query: { id: applicationId },
    });
  } else {
    alert('Unexpected error happened, please refresh the page and try again');
  }
};

export const getTheme = async (
  context: any
): Promise<GetServerSidePropsResult<{ [key: string]: any }>> => {
  const { req, res, query } = context;
  const host = req.headers.host;
  const cookies = new Cookies(req, res);
  const queryTheme = query.theme as string;
  const cookiesTheme = cookies.get('theme');
  let theme = queryTheme || cookiesTheme || null;

  if (host.startsWith('dl.') && !theme?.includes('hussein')) theme = host;
  if (!theme && host.startsWith('www.')) theme = host;

  const customerId = query.customerId;
  let themeOptions = {} as Theme;
  let slug = null;

  cookies.set('_vercel_no_cache', '1');

  if (customerId || cookies.get('isMember') === 'true') {
    cookies.set('isMember', 'true');
    themeOptions = boTheme;
  } else if (theme) {
    cookies.set('theme', theme);
    themeOptions = await fetch(`${API_ENDPOINT}/themes/${theme}`)
      .then((res) => res.json())
      .then(({ data }) => data);
    if (themeOptions) slug = theme;
  }

  themeOptions = Object.assign({ ...initialState }, themeOptions);

  return {
    props: { theme: { ...themeOptions, slug } || null },
  };
};

export const resetForm = (state: GlobalState, _payload?: null): any => {
  return {
    ...state,
    form: {
      licenceNumber: '',
      nin: '',
      postcode: '',
    },
  };
};

export const updateForm = (
  state: GlobalState,
  payload: { licenceNumber: string; nin: string; postcode: string }
): any => {
  return {
    ...state,
    form: {
      ...state.form,
      ...payload,
    },
  };
};

export const sendGTagSubmitEvent = (
  eventType: 'submit_application' | 'conversion'
): void => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { gtag } = window;
    if (gtag) gtag('event', eventType);
  }
};

export const isValidCreditCardNumber = (creditCardNumber: string) => {
  if (/[^0-9-\s]+/.test(creditCardNumber)) {
    return false;
  }

  const creditCardNumberArray = creditCardNumber.replace(/\D/g, '').split('');
  const creditCardNumberArrayLength = creditCardNumberArray.length;

  if (creditCardNumberArrayLength < 13 || creditCardNumberArrayLength > 19) {
    return false;
  }

  const sum = creditCardNumberArray
    .map((digit, index) => {
      let digitValue = parseInt(digit, 10);
      if (index % 2 === 0 && (digitValue *= 2) > 9) {
        return digitValue - 9;
      }

      return digitValue;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return sum % 10 === 0;
};

export const updateStyles = ({
  element,
  styles,
  baseStyles,
  action,
}: {
  element: HTMLElement;
  styles: Partial<CSSStyleDeclaration>;
  baseStyles?: Partial<CSSStyleDeclaration>;
  action?: 'reset';
}): void => {
  const isReset = action === 'reset';
  for (const propertyName in styles) {
    element.style[propertyName] = isReset
      ? baseStyles?.[propertyName] || 'initial'
      : (styles[propertyName] as string);
  }
};

export const isValidDate = (date: string, format: string) => {
  return dayjs(date, format).format(format) === date;
};

export const checkIfSubscriptionTheme = (
  themeSlug: string | null,
  landingPage = 'v1'
): boolean => {
  const themeSlugStartsWithSub =
    (themeSlug && themeSlug.startsWith('sub_')) ||
    (themeSlug && themeSlug.startsWith('dl.'));
  const subscriptionThemeSlugs = [
    null,
    'sub',
    'dlng',
    'dlxiiihk',
    'dlxivhk',
    'dlxvihk',
    'dlxviihk',
    'hussein',
    'hussein2',
    'checkbox-price',
    'dl_ab_newcheck_v1',
  ];
  const isDlAssistant = ['v12', 'v13'].includes(landingPage);

  return (
    !isDlAssistant &&
    (subscriptionThemeSlugs.includes(themeSlug) || themeSlugStartsWithSub)
  );
};

export const subscriptionPricePeriod = {
  v0: '£89 every 3-months',
  v1: '£89 every 3-months',
  v2: '£89 every 3-months',
  v3: '£89 every 3-months',
  v4: '£89 every 3-months',
  v5: '£89 every 3-months',
  v6: '£89 every 3-months',
  v7: '£89 every 3-months',
  v8: '£39 every month',
  v9: '£69 every 2-months',
  v10: '£89 every 3-months',
  v11: '£19 every 14 days',
};

export const getSubscriptionProduct = (landingPage: string): string => {
  switch (landingPage) {
    case 'v7':
      return SUBSCRIPTION_NO_TRIAL;
    case 'v8':
      return SUBSCRIPTION_1M;
    case 'v9':
      return SUBSCRIPTION_2M;
    case 'v10':
      return SUBSCRIPTION_3M_7D;
    case 'v11':
      return SUBSCRIPTION_14D;
    default:
      return SUBSCRIPTION_3M;
  }
};
